import React, { useState, useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import { auth } from "./firebase"; // Import firebase configuration
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast, Toaster } from "react-hot-toast";
const BASE_URL=process.env.REACT_APP_BASE_URL;
const Signup = () => {
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [user, setUser] = useState(null);
  const [countdown, setCountdown] = useState(50); // Timer for Resend OTP button
  const [resendDisabled, setResendDisabled] = useState(true);
  const [viewSuccess, setViewSuccess] = useState("block");
  const [showBtn, setShowBtn] = useState("none");
  const [showLogin, setShowLogin] = useState("none");

  const [userData, setUserData] = useState(null);
	const [formData, setFormData] = useState({
		firstName: '',
    lastName: '',
		email: '',
    password:'',
		mobileNumber: ''
	  });
    const navigate = useNavigate();
    const [errorData, setErrorData] = useState('');
    const [successData, setSuccessData] = useState('');
    const [showModal, setShowModal] = useState(false);
   
	  const handleInputChange = (e) => {
		const { name, value, type, checked, files } = e.target;
    const newValue = (name === 'firstName' || name === 'lastName')
    ? value.charAt(0).toUpperCase() + value.slice(1)
    : value;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : type === 'file' ? files[0] : newValue
    }));
    //alert(newValue);
	  };
//     function setLogin(){
//     sessionStorage.setItem('candidateId', userData.candidate.id);
//     sessionStorage.setItem('candidateFName', userData.candidate.firstname);
//     sessionStorage.setItem('login', "yes");
//     sessionStorage.setItem('user', JSON.stringify(userData));
//     navigate('/biodata');
    
// } 
const handleClickLogin=async (e) => {
  e.preventDefault();
  const updatedFormData = {
      mobile: ph,        // Set mobile field
      password: ph       // Set password field
    };
    console.log(JSON.stringify(updatedFormData));
try {
  const response = await axios.post(`${BASE_URL}loginemp`, updatedFormData);
     if(response.status===200){
       const data =response.data;
       setUserData(data);
    sessionStorage.setItem('candidateId', data.candidate.id);
    sessionStorage.setItem('candidateFName', data.candidate.firstname);
    sessionStorage.setItem('login', "yes");
    sessionStorage.setItem('user', JSON.stringify(data));
    navigate('/biodata');
     } 
   } catch (error) {
     setErrorData("Something went wrong,Click Login again..");
        sessionStorage.setItem('login', 'no');
      }
    };
	  const handleSubmit = async (e) => {
		e.preventDefault();
		
		try {
		  const formDataToSend = new FormData();
		  for (const key in formData) {
			if (key === 'whatsappUpdates') {
				formDataToSend.append(key, formData[key] ? '1' : '0');
			  } else {
				formDataToSend.append(key, formData[key]);
			  }
		  }

		 // const response = await axios.post('http://localhost:8000/api/register', formDataToSend, {
       const response = await axios.post(`${BASE_URL}register`, formDataToSend, {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		  });
      setErrorData("");
    //  setErrorData("Registration Success..");
      setShowModal(true);
		 // alert('Registration successful:', response.data);
     setTimeout(() => {
      setShowModal(false);
      setShowBtn("none");
      setShowLogin("block");
      setViewSuccess("none");
    }, 4000); 
		  // Handle successful registration (e.g., show success message, redirect)
		} catch (error) {
		  console.log('Registration failed:', error.response ? error.response.data : error.message);
      setErrorData("Registration Failed.."+ error.response.data.message);
		  // Handle error (e.g., show error message)
		}
	  };
	  useEffect(() => {
		//console.log('Form data updated:', formData);
	  }, [formData]);
    const modalStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    };
  
    const modalContentStyle = {
      backgroundColor: 'white',
      padding: '20px',
      borderRadius: '8px',
      width: '300px',
      textAlign: 'center',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    };
    function onCaptchVerify() {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              onSignup();
            },
            "expired-callback": () => {},
          }
        );
      }
    }
  
    function onSignup() {
      setLoading(true);
      onCaptchVerify();
  
      const appVerifier = window.recaptchaVerifier;
  
      const formatPh = "+" + ph;
  
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setShowOTP(true);
          setCountdown(30);
          setResendDisabled('true');
          toast.success("OTP sent successfully!");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  
    function onOTPVerify() {
      setLoading(true);
      window.confirmationResult
        .confirm(otp)
        .then(async (res) => {
          console.log(res);
          setUser(res.user);
          setLoading(false);
          setFormData((prevFormData) => ({
            ...prevFormData,   // Spread the existing formData
            mobileNumber: ph ,  // Add the new OTP field
            password: ph   // Add the new OTP field
          }));
          setShowBtn("block")
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    useEffect(() => {
      if (countdown > 0) {
        const timer = setTimeout(() => {
          setCountdown(countdown - 1);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setResendDisabled(false); // Enable Resend OTP button after 30 seconds
      }
    }, [countdown]);

    const showMobileNumberField = formData.firstName && formData.lastName && formData.email;
    

  return (
    <div>
        <Header />
        <section className="practise-part section-p">
        <div className="container">
			<div className="row align-items-center justify-content-center">
				<div className="col-lg-10">
					<p><b>Register with OTP</b></p>
					<div className="logview">
						<div className="row">
							<div className="col-lg-8 p-0">
							  <div className="form-2-wrappers p-3 mt-3">
								
<form onSubmit={handleSubmit}>
  <div className="mb-3 form-box">
    <label>First Name</label>
    <input 
      type="text" 
      className="form-control" 
      name="firstName" 
      value={formData.firstName}
      onChange={handleInputChange}
      placeholder="Enter Your First Name" 
      required 
    />
  </div>
  <div className="mb-3 form-box">
    <label>Last Name</label>
    <input 
      type="text" 
      className="form-control" 
      name="lastName" 
      value={formData.lastName}
      onChange={handleInputChange}
      placeholder="Enter Your Last Name" 
      required 
    />
  </div>
  <div className="mb-3 form-box">
    <label>Email ID</label>
    <input 
      type="email" 
      className="form-control" 
      name="email" 
      value={formData.email}
      onChange={handleInputChange}
      placeholder="Enter Your email" 
      required 
    />
    <span style={{fontSize:"10px"}}>Recruiter Will send Notifications to this Email</span>
  </div>

  {/* <div className="mb-3 form-box">
    <label>Password</label>
    <input 
      type="password" 
      className="form-control" 
      name="password" 
      value={formData.password}
      onChange={handleInputChange}
      placeholder="Enter Your Password" 
      required 
      minLength={8}
    />
  </div> */}
  {/* <div className="mb-3 form-box" >
    <label>Mobile Number</label>
    <input 
      type="text" 
      className="form-control" 
      name="mobileNumber" 
      value={formData.mobileNumber}
      onChange={handleInputChange}
      placeholder="Enter Your Number" 
      required 
    />
    
    <span style={{fontSize:"10px"}}>Recruiter Will Call you on this Number</span>
  </div> */}
    {showMobileNumberField && (
  <div className="mb-3 form-box">
  <div>
      <Toaster toastOptions={{ duration: 4000 }} />
      <div id="recaptcha-container"></div>
      {user ? (
        <p className="text-center font-medium text-2xl" style={{display:viewSuccess}}>
          👍Your Mobile Number Verified.<br /> Please Click Register to Complete the Process.
        </p>
      ) : (
        <div className="flex flex-col gap-4 rounded-lg ">
        
          {showOTP ? (
            <>
              {/* <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full">
                <BsFillShieldLockFill size={30} />
              </div> */}
              <label
                htmlFor="otp"
                className="font-bold text-xl  text-center"
              >
                Enter your OTP
              </label>
              <br />
              <OtpInput
                value={otp}
                onChange={setOtp}
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus
                className="opt-container "
              ></OtpInput><br /><br />
              {/* <button type="button"
                onClick={onSignup}
                className="btn w-60 mb-2"
                style={{ backgroundColor: '#3498db', color: '#fff' }}
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                Resend OTP
              </button> */}
              <div style={{ display: 'flex', gap: '10px' }}>
                    <button  type="button"
                className="btn w-60 mb-2"
                style={{ backgroundColor: '#3498db', color: '#fff' }}
                onClick={onSignup}
                disabled={resendDisabled} // Disable button during countdown
              >
                {loading && <CgSpinner size={20} className="mt-1 animate-spin" />}
                <span>{resendDisabled ? `Resend OTP in ${countdown}s` : "Resend OTP"}</span>
              </button>
              <button type="button"
                onClick={onOTPVerify}
                className="btn w-60 mb-2 btn-secondary login-btn"
              >
                {loading && (
                  <CgSpinner size={20} className=" animate-spin" />
                )}
                Verify OTP
              </button>
              </div>
            </>
          ) : (
            <>
              <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full" style={{display:"none"}}>
                <BsTelephoneFill size={30} />
              </div>
              <label
                htmlFor=""
                className="font-bold text-xl  text-center"
              >
                Verify your phone number
              </label>
              <PhoneInput country={"in"} value={ph} onChange={setPh} />
             
             
              <span style={{fontSize:"10px"}}>Recruiter Will Call you on this Number</span>
              <br /><br />
              <button type="button"
                onClick={onSignup}
                className="btn btn-outline-secondary login-btn w-60 mb-2"
              >
                {loading && (
                  <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Send OTP</span>
              </button>
            </>
          )}
        </div>
      )}
    </div>
    </div>
  )}
  <div className="mb-3 form-box"  style={{display:showBtn}}>
    <p><input type="checkbox" /> By Clicking Register, you agree to the <a href="" data-toggle="modal" data-target="#exampleModal">
     <b>Terms and Conditions & Privacy Policy</b> </a> of Seagulljobs4u.com</p>
  </div>
  <button type="submit"  className="btn btn-outline-secondary login-btn mb-2" style={{display:showBtn}}>Register Now</button>
  <button type="button" onClick={handleClickLogin} className="btn btn-outline-secondary login-btn mb-2" style={{display:showLogin}}>Proceed to update Profile</button>
  <h5 className="text-center mb-3 pb-3" style={{color:"red",fontSize:"20px"}}>{errorData}</h5>
</form>

							  </div>
							</div>
							<div className="col-lg-4 p-5">
								<div className="social-logins  type--A">
									{/* <div className="vrline">
									<h5 className="text-center">or</h5>
									</div> 
									
									<h4 className="mb-2 text-center">Continue With</h4> */}
									{/* <GoogleLoginButton />
									<FacebookLoginButton /> */}
									{/* <button className="btn btn-outline-secondary w-100 mb-2"><i className="fa fa-google text-danger"> </i> Google</button>
									<button className="btn btn-outline-secondary w-100  mb-2"><i className="fa fa-facebook-f text-primary"> </i> Facebook</button> */}
								</div>
							</div>
							
						  </div>	
					</div>
				</div>
			</div>
		</div>
    </section>
    <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Terms and Conditions & Privacy Policy</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
      <div className="container">
      <div className="row">
      <div className="terms col-lg-12">
      <h4 class="text-danger"> Terms and Conditions </h4>
      <ul>
      <li>
       User Agreement: By using the Seagull Jobs 4U website, you agree to be bound
      by the terms and conditions set forth in this agreement. If you do not agree to
      these terms, please do not access or use our services.</li>
      <li> Job Listings: We make every effort to ensure the accuracy and quality of job
      listings on our portal. However, we do not guarantee the authenticity or reliability
      of the information provided by employers. We recommend job seekers to use their
      discretion and take precautions before applying for any job listing.</li>
      <li> Job Seeker Registration: Job seekers must create an account on our portal to
      access job listings and apply for jobs. You agree to provide accurate and updated
      information during the registration process and maintain the confidentiality of your
      login credentials. In case of any unauthorized use of your account, please inform
      us immediately.</li>
      <li> Employer Registration: Employers must register on our portal to post job listings
      and access our services. By registering, you agree to provide accurate and
      updated information and maintain the confidentiality of your login credentials. We
      reserve the right to verify the information provided by employers before approving
      their registration.</li>
      <li> Job Applications: Job seekers can apply for jobs through our portal. However,
      we do not guarantee that your application will be considered or that you will be
      placed in a job. Employers reserve the right to accept or reject applications at
      their own discretion.</li>
      <li> Third-Party Links: Our portal may contain links to other websites, including those
      of employers. We are not responsible for the content, policies, or practices of
      these third-party websites and recommend users to read their terms and
      conditions before using their services.</li>
      <li> Service Fees: Job seekers do not have to pay any fees to access our services.
      Employers may be charged a fee for job postings and other services, which will be
      communicated to them during registration.</li>
      <li> Disclaimer of Warranties: Seagull Jobs 4U makes no representations or
      warranties of any kind, express or implied, regarding the accuracy, reliability, or
      completeness of the information provided on our portal. We do not guarantee that
      our services will be error-free or uninterrupted.</li>
      <li> Indemnity: You agree to indemnify and hold Seagull Jobs 4U and its affiliates,
      employees, and agents harmless from any claims, damages, or expenses arising
      from your use of our portal or violation of the terms and conditions set forth in this
      agreement.</li>
      <li>1 Modification of Terms and Conditions: We reserve the right to modify these
      terms and conditions at any time without prior notice. We recommend users to
      regularly review the terms and conditions to stay updated about any changes.</li>
      
      <li> Governing Law: This agreement is governed by the laws of India, and any
      disputes arising out of or in connection with this agreement will be subject to the
      exclusive jurisdiction of the courts of Mumbai, India.
      </li>
      </ul>
      <h6>We believe in providing a transparent and efficient platform for job seekers and
      employers, and we strive to maintain the highest ethical standards in all our
      operations. By using our portal, you agree to abide by these terms and conditions
      and adhere to our policies. Thank you for choosing Seagull Jobs 4U.</h6>
      
      </div>
							<div className="Privacy pt-2 col-lg-12">
                <h4 class="text-danger"> Privacy Policy </h4>
                <h6 className="mb-3  text-justify">At Seagull Jobs 4 U, we take the privacy of our users very seriously. This privacy
policy outlines the type of information we collect from our users and how we use,
disclose, and protect that information.</h6>	

<h5>Information We Collect:</h5>
<ul>
<li>Personal information such as name, email address, phone number, and resume
when you register an account with us or apply for a job through our portal.</li> 
<li>Information about your work experience, education, skills, and employment
preferences when creating a profile or applying for a specific job.</li> 
<li>Information automatically collected through cookies and other tracking
technologies when you visit our website, such as IP address, browser type, and
operating system.</li> 
</ul>

<h5>How We Use Your Information:</h5>
<ul>
<li> To create and maintain your account on Seagull Jobs 4 U and provide
personalized job recommendations.</li>
<li> To connect you with employers and job opportunities that match your skills and
preferences.</li>
<li> To analyze job search trends and improve our services.</li>
<li> To send you marketing emails and updates about new job openings, with your
consent.</li>
<li> To comply with legal obligations and protect the rights and interests of Seagull
Jobs 4 U and its users.</li>

</ul>

<h5>How We Disclose Your Information:</h5>

<p>We may disclose your personal information to the following parties:</p>
<ul>
<li> Employers who have listed job openings relevant to your profile and preferences.</li>
<li> Third-party service providers who assist us in providing our services, such as
payment processing and marketing.</li>
<li> Government agencies or legal authorities, if required by law or to protect our
rights and interests.</li>
</ul>
<h5>Protection of Your Information:</h5>
<h6>We take all necessary measures to protect your personal information from
unauthorized access, use, or disclosure. However, we cannot guarantee the
absolute security of information transmitted through the internet.</h6>
<h5>Third-Party Links:</h5>
<h6>Our website may contain links to third-party websites that are not controlled or
operated by us. We are not responsible for the privacy practices of these websites
and recommend that you review their privacy policies before providing any
personal information. </h6>	

					   </div>



							
						  </div>	
						  </div>	
      </div>
      
    </div>
  </div>
</div>

    <Footer />
    {showModal && (
        <div style={modalStyle}>
          <div style={modalContentStyle}>
            <h3>Registration Success</h3>
            <p>Hi, Your Registration is successful </p>
          </div>
        </div>
      )}
    </div>
  )
}

export default Signup