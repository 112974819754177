import React, { useState,useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
const PrivacyPolicy = () => {
    return (
        <div>
        <body class="home-3">
        <Header />
        <section className="practise-part section-p pt-5">
        <div className="container">
			<div className="row align-items-center justify-content-center">
				<div className="col-lg-10">
					<div className="logview pt-3">
					<div className="section-head text-center">
                    <h2>Privacy Policy</h2>
                </div>
						<hr></hr>
						<div className="row">
							<div className="Privacy col-lg-12">
                <h6 className="mb-3  text-justify">At Seagull Jobs 4 U, we take the privacy of our users very seriously. This privacy
policy outlines the type of information we collect from our users and how we use,
disclose, and protect that information.</h6>	

<h5>Information We Collect:</h5>
<ul>
<li>Personal information such as name, email address, phone number, and resume
when you register an account with us or apply for a job through our portal.</li> 
<li>Information about your work experience, education, skills, and employment
preferences when creating a profile or applying for a specific job.</li> 
<li>Information automatically collected through cookies and other tracking
technologies when you visit our website, such as IP address, browser type, and
operating system.</li> 
</ul>

<h5>How We Use Your Information:</h5>
<ul>
<li> To create and maintain your account on Seagull Jobs 4 U and provide
personalized job recommendations.</li>
<li> To connect you with employers and job opportunities that match your skills and
preferences.</li>
<li> To analyze job search trends and improve our services.</li>
<li> To send you marketing emails and updates about new job openings, with your
consent.</li>
<li> To comply with legal obligations and protect the rights and interests of Seagull
Jobs 4 U and its users.</li>

</ul>

<h5>How We Disclose Your Information:</h5>

<p>We may disclose your personal information to the following parties:</p>
<ul>
<li> Employers who have listed job openings relevant to your profile and preferences.</li>
<li> Third-party service providers who assist us in providing our services, such as
payment processing and marketing.</li>
<li> Government agencies or legal authorities, if required by law or to protect our
rights and interests.</li>
</ul>
<h5>Protection of Your Information:</h5>
<h6>We take all necessary measures to protect your personal information from
unauthorized access, use, or disclosure. However, we cannot guarantee the
absolute security of information transmitted through the internet.</h6>
<h5>Third-Party Links:</h5>
<h6>Our website may contain links to third-party websites that are not controlled or
operated by us. We are not responsible for the privacy practices of these websites
and recommend that you review their privacy policies before providing any
personal information. </h6>	

					   </div>

							
						  </div>	
					
			   
                          </div>

</div>
</div>
</div>
</section>
<Footer />
</body>

</div>
  )
}

export default PrivacyPolicy