import React, { useState,useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
const TermsAndConditions = () => {
return (
    <div>
    <body class="home-3">
    <Header />
    <section className="practise-part section-p pt-5">
    <div className="container">
        <div className="row align-items-center justify-content-center">
            <div className="col-lg-10">
                <div className="logview pt-3">
                <div className="section-head text-center">
                <h2>terms and conditions</h2>
            </div>
                    <hr></hr>
                    <div className="row">
                        <div className="terms col-lg-12">
      
<ul>
<li>
 User Agreement: By using the Seagull Jobs 4U website, you agree to be bound
by the terms and conditions set forth in this agreement. If you do not agree to
these terms, please do not access or use our services.</li>
<li> Job Listings: We make every effort to ensure the accuracy and quality of job
listings on our portal. However, we do not guarantee the authenticity or reliability
of the information provided by employers. We recommend job seekers to use their
discretion and take precautions before applying for any job listing.</li>
<li> Job Seeker Registration: Job seekers must create an account on our portal to
access job listings and apply for jobs. You agree to provide accurate and updated
information during the registration process and maintain the confidentiality of your
login credentials. In case of any unauthorized use of your account, please inform
us immediately.</li>
<li> Employer Registration: Employers must register on our portal to post job listings
and access our services. By registering, you agree to provide accurate and
updated information and maintain the confidentiality of your login credentials. We
reserve the right to verify the information provided by employers before approving
their registration.</li>
<li> Job Applications: Job seekers can apply for jobs through our portal. However,
we do not guarantee that your application will be considered or that you will be
placed in a job. Employers reserve the right to accept or reject applications at
their own discretion.</li>
<li> Third-Party Links: Our portal may contain links to other websites, including those
of employers. We are not responsible for the content, policies, or practices of
these third-party websites and recommend users to read their terms and
conditions before using their services.</li>
<li> Service Fees: Job seekers do not have to pay any fees to access our services.
Employers may be charged a fee for job postings and other services, which will be
communicated to them during registration.</li>
<li> Disclaimer of Warranties: Seagull Jobs 4U makes no representations or
warranties of any kind, express or implied, regarding the accuracy, reliability, or
completeness of the information provided on our portal. We do not guarantee that
our services will be error-free or uninterrupted.</li>
<li> Indemnity: You agree to indemnify and hold Seagull Jobs 4U and its affiliates,
employees, and agents harmless from any claims, damages, or expenses arising
from your use of our portal or violation of the terms and conditions set forth in this
agreement.</li>
<li>1 Modification of Terms and Conditions: We reserve the right to modify these
terms and conditions at any time without prior notice. We recommend users to
regularly review the terms and conditions to stay updated about any changes.</li>

<li> Governing Law: This agreement is governed by the laws of India, and any
disputes arising out of or in connection with this agreement will be subject to the
exclusive jurisdiction of the courts of Mumbai, India.
</li>
</ul>
<h6>We believe in providing a transparent and efficient platform for job seekers and
employers, and we strive to maintain the highest ethical standards in all our
operations. By using our portal, you agree to abide by these terms and conditions
and adhere to our policies. Thank you for choosing Seagull Jobs 4U.</h6>

</div>

                        
                      </div>	
                
           
                      </div>

</div>
</div>
</div>
</section>
<Footer />
</body>

</div>
)
}

export default TermsAndConditions