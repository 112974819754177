import React, { useState,useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
const Careers = () => {
    return (
        <div>
            <body class="home-3">
            <Header />
            <section className="practise-part section-p pt-5">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-10">


                     </div>
			    </div>
		    </div>
            </section>
            <Footer />
            </body>
   
        </div>
  )
}

export default Careers