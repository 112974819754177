import React, { useState,useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
const Aboutus = () => {
	return (
		<div>
			<body class="home-3">
			<Header />
			<section className="practise-part section-p pt-5">
			<div className="container">
				<div className="row align-items-center justify-content-center">
					<div className="col-lg-12">
						<div className="logview pt-3">
						<div className="section-head text-left">
						<h2>Welcome To Seagull Jobs 4 U</h2>
					</div>
							<hr></hr>
							<div className="row">
								<div className="col-lg-12">
	
	<h6>
	Seagull Jobs 4 U is a comprehensive job portal that is designed specifically for
	individuals seeking employment opportunities in various industries, such as the oil
	and gas sector, hospitality, construction, medical, and more. It is the first of its kind
	to provide a dedicated platform for job seekers to find their dream job based on
	their precise requirements.</h6>
	<h6 className="mb-3  text-justify">
	Seagull Jobs 4 U is a part of Seagull International, one of India's most respected
	overseas recruitment firms with 39 years of experience. With a 5-star rating from
	the Government of India, Ministry of External Affairs, Seagull International has
	established itself as a trusted and reliable recruitment company, providing
	exceptional services to both job seekers and companies worldwide.</h6>
	<h6 className="mb-3  text-justify">
	At Seagull Jobs 4 U, our mission is to connect job seekers with reputable
	international companies and help fulfill their career aspirations. We understand the
	challenges and complexities of the job seeking process, especially when it comes
	to finding the right job in a foreign country. That's why we have designed our
	platform to make the process efficient, convenient, and reliable.
	Our job portal features a user-friendly interface, making it easy for job seekers to
	browse and apply for job opportunities in various industries. We have a vast
	database of job listings from reputable companies, providing a diverse range of
	employment opportunities for our users.</h6>
	<h6 className="mb-3  text-justify">
	What sets Seagull Jobs 4 U apart from other job portals is our commitment to
	precision and attention to detail. We believe that finding the right job goes beyond
	matching keywords and qualifications; it requires understanding an individual's
	unique needs and preferences. That's why we have incorporated advanced search
	filters that allow job seekers to narrow down their search based on their
	requirements, such as preferred location, industry, salary range, experience, and
	more.</h6>
	<h6 className="mb-3  text-justify">
	We also offer a variety of resources and tools to support job seekers in their job
	search journey. From resume building services to career advice, our team is
	dedicated to helping individuals improve their job prospects and achieve their
	career goals.</h6>
	<h6 className="mb-3  text-justify">
	At Seagull Jobs 4 U, we strive for excellence and prioritize the satisfaction of our
	users above all else. We are continuously improving and updating our platform to
	provide the best user experience and ensure that job seekers find the right job for
	them. Our commitment to our users and our passion for providing reliable
	recruitment services make us the leading job portal for international job seekers in
	India.</h6>
	<h6 className="mb-3  text-justify">
	In conclusion, Seagull Jobs 4 U is not just a job portal; it's a dedicated platform
	that is backed by years of experience, expertise, and a proven track record.
	Whether you're a fresh graduate seeking your first job or an experienced
	professional looking for new opportunities overseas, Seagull Jobs 4 U is here to
	help you make your dream job a reality. 
		
	</h6>
	
	
					<h6 className="mb-3  text-justify">
					Seagull Jobs 4 U is a dedicated job portal developed by Seagull International, a highly
	respected Indian overseas recruitment firm with over 39 years of experience.
	Specializing in industries such as oil and gas, hospitality, construction, and medical,
	Seagull Jobs 4 U connects job seekers with reputable international companies, making
	it easier to secure the right job in foreign countries. Rated 5-stars by the Government
	of India's Ministry of External Affairs, Seagull International brings a trusted and reliable
	foundation to this platform.</h6>
	<h6 className="mb-3  text-justify">The job portal is designed with a user-friendly interface and advanced search filters,
	allowing job seekers to precisely tailor their search by industry, location, salary range,
	and more. This level of customization ensures that users find employment opportunities
	that meet their unique preferences, setting Seagull Jobs 4 U apart from other job
	portals. Additionally, the platform offers valuable resources like resume building and
	career advice to help individuals enhance their job search and career growth.</h6>
	<h6 className="mb-3  text-justify">Seagull Jobs 4 U is more than a job portal; it's a comprehensive platform focused on
	user satisfaction and reliability, continuously updating to improve the experience for job
	seekers. From fresh graduates to seasoned professionals, this platform is committed to
	helping individuals achieve their career aspirations and find fulfilling job opportunities
	abroad.</h6>	
	
	
						   </div>
	
								
							  </div>	
						
		

          <div className="row justify-content-center">
		<div className="section-head text-center">
                    <h2>OUR Top Recruiters</h2>
                </div>
				<div className="row justify-content-center">
					<div className="col-lg-2 col-4 1"><img className="clientele-logo"  src="assets/images/clients/descon.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 2"><img className="clientele-logo"  src="assets/images/clients/2.png" alt="" /> </div>
					<div className="col-lg-2 col-4 3"><img className="clientele-logo"  src="assets/images/clients/3.png" alt="" /> </div>
					<div className="col-lg-2 col-4 4"><img className="clientele-logo"  src="assets/images/clients/4.png" alt="" /> </div>
					<div className="col-lg-2 col-4 5"><img className="clientele-logo"  src="assets/images/clients/altradcape.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 6"><img className="clientele-logo"  src="assets/images/clients/baskinrobbins.jpg" alt="" /> </div>
 					<div className="col-lg-2 col-4 7"><img className="clientele-logo"  src="assets/images/clients/samsung.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 8"><img className="clientele-logo"  src="assets/images/clients/drydocks.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 9"><img className="clientele-logo"  src="assets/images/clients/kaefer.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 10"><img className="clientele-logo"  src="assets/images/clients/nps.png" alt="" /> </div>
					<div className="col-lg-2 col-4 11"><img className="clientele-logo"  src="assets/images/clients/doosan.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 12"><img className="clientele-logo"  src="assets/images/clients/sts.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 13"><img className="clientele-logo"  src="assets/images/clients/galdari.png" alt="" /> </div>
					<div className="col-lg-2 col-4 14"><img className="clientele-logo"  src="assets/images/clients/emrill.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 15"><img className="clientele-logo"  src="assets/images/clients/galfar.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 16"><img className="clientele-logo"  src="assets/images/clients/lamprell.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 17"><img className="clientele-logo"  src="assets/images/clients/steel.png" alt="" /> </div>	
					<div className="col-lg-2 col-4 19"><img className="clientele-logo"  src="assets/images/clients/bec.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 20"><img className="clientele-logo"  src="assets/images/clients/arabian.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 21"><img className="clientele-logo"  src="assets/images/clients/oman.png" alt="" /> </div>
					<div className="col-lg-2 col-4 22"><img className="clientele-logo"  src="assets/images/clients/SAS.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 23"><img className="clientele-logo"  src="assets/images/clients/alshaya.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 24"><img className="clientele-logo"  src="assets/images/clients/al ahelia.png" alt="" /> </div>
					<div className="col-lg-2 col-4 25"><img className="clientele-logo"  src="assets/images/clients/nbtc.jpg" alt="" /> </div>
					<div className="col-lg-2 col-4 26"><img className="clientele-logo"  src="assets/images/clients/mc don.png" alt="" /> </div>
					<div className="col-lg-2 col-4 27"><img className="clientele-logo"  src="assets/images/clients/madina.png" alt="" /> </div>
					<div className="col-lg-2 col-4 28"><img className="clientele-logo"  src="assets/images/clients/adani.png" alt="" /> </div>
					<div className="col-lg-2 col-4 29"><img className="clientele-logo"  src="assets/images/clients/reliance jio.png" alt="" /> </div>
					<div className="col-lg-2 col-4 30"><img className="clientele-logo"  src="assets/images/clients/shappo.png" alt="" /> </div>
					<div className="col-lg-2 col-4 31"><img className="clientele-logo"  src="assets/images/clients/dopet.png" alt="" /> </div>
					<div className="col-lg-2 col-4 32"><img className="clientele-logo"  src="assets/images/clients/ramsis en.png" alt="" /> </div>
					<div className="col-lg-2 col-4 33"><img className="clientele-logo"  src="assets/images/clients/mcsc.jpg" alt="" /> </div>
					
				</div>
			</div>

      </div>

				</div>
			</div>
		</div>
    </section>
    <Footer />
    </body>
   
    </div>
  )
}

export default Aboutus