import React, { useState,useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
const Legal = () => {
    return (
        <div>
        <body class="home-3">
        <Header />
        <section className="practise-part section-p pt-5">
        <div className="container">
            <div className="row align-items-center justify-content-center">
                <div className="col-lg-10">
                    <div className="logview pt-3">
                    <div className="section-head text-left">
                    <h2>Disclaimer</h2>
                </div>
                        <hr></hr>
                        <div className="row">
                            <div className="terms col-lg-12">

    <h6>The information and services provided on Seagull Jobs 4 U are for general
information purposes only. While we strive to keep the information accurate and
up-to-date, we make no representations or warranties of any kind, express or
implied, about the completeness, accuracy, reliability, suitability, or availability
with respect to the website or the information, products, services, or related
graphics contained on the website for any purpose. 
<br></br><br></br>
Any reliance you place on such information is therefore strictly at your own risk. In
no event will we be liable for any loss or damage including without limitation,
indirect or consequential loss or damage, or any loss or damage whatsoever
arising from loss of data or profits arising out of, or in connection with, the use of
this website. 
<br></br><br></br>
Through this website, you may be able to link to other websites that are not under
the control of Seagull Jobs 4 U. We have no control over the nature, content, and
availability of those sites. The inclusion of any links does not necessarily imply a
recommendation. </h6>
    
    
    
    
                       </div>
    
                            
                          </div>	
                    
               
                          </div>
    
    </div>
    </div>
    </div>
    </section>
    <Footer />
    </body>
   
        </div>
  )
}

export default Legal