import React, { useState, useEffect } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
} from 'react-share';

function ShareButtons({ jobid }) {
 
 const shareUrl = `https://seagulljobs4u.com/sharejob?job=${jobid}`;//window.location.href; // The URL you want to share
 const title = 'Check out this Job! Great opportunity for you ';
 const jobImageUrl = 'https://seagulljobs4u.com/assets/images/logo.png';
 //const message = `${title} ${shareUrl} ${imageUrl}`;
 //const jobImageUrl = `http://yourdomain.com/path/to/image-${jobId}.jpg`;
 useEffect(() => {
  const setMetaTag = (name, content) => {
    let metaTag = document.querySelector(`meta[property="${name}"]`);
    if (!metaTag) {
      metaTag = document.createElement('meta');
      metaTag.setAttribute('property', name);
      document.head.appendChild(metaTag);
    }
    metaTag.setAttribute('content', content);
  };

  setMetaTag("og:title", title);
  setMetaTag("og:description", "A great job opportunity awaits. Apply now!");
  setMetaTag("og:image", jobImageUrl);
  setMetaTag("og:url", shareUrl);

  setMetaTag("twitter:title", title);
  setMetaTag("twitter:description", "A great job opportunity awaits. Apply now!");
  setMetaTag("twitter:image", jobImageUrl);
  setMetaTag("twitter:card", "summary_large_image");
}, [jobid, title, jobImageUrl, shareUrl]);
  return (
    <div style={{ display: 'flex', gap: '10px' }}>
    <FacebookShareButton url={shareUrl} quote={title}>
      <FacebookIcon size={20} round />
    </FacebookShareButton>

    <TwitterShareButton url={shareUrl} title={title}>
      <TwitterIcon size={20} round />
    </TwitterShareButton>

    <LinkedinShareButton url={shareUrl}>
      <LinkedinIcon size={20} round />
    </LinkedinShareButton>

    <WhatsappShareButton url={shareUrl} title={title} separator=":: ">
      <WhatsappIcon size={20} round />
    </WhatsappShareButton>

    <TelegramShareButton url={shareUrl} title={title}>
      <TelegramIcon size={20} round />
    </TelegramShareButton>

    <EmailShareButton url={shareUrl} subject={title} body="Check out this job opportunity!">
      <EmailIcon size={20} round />
    </EmailShareButton>
  </div>
  );
}

export default ShareButtons;
